@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@100;200&display=swap');

.header{
    background: #d6feff;
    /* background: linear-gradient(0deg, rgba(85,206,244,1) 0%, rgba(133,94,244,1) 100%); */
    position: relative;
    width: 100%;
    height: 10%;
    min-height: 40px;
    text-align: center;
    padding: 0.5%;
    padding-top: 1%;
    text-align: center;
    display: block;
    overflow: hidden;
    position: inherit;
    border-bottom: 1px solid #3a6d06;
}
.title{
    color: #3a6d06;
    font-size: 370%;
    font-weight: 200;
    padding: auto;
    margin-right: 7%;
    vertical-align: middle;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1px;
    display: inline;
}
.logo{
    max-height: 100%;
    height: 85px;
    float: left;
    padding: 12px;
    padding-top: 1px;
    vertical-align: middle;
}