@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-div{
  position: relative;
  height: initial;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
  align-items: center;
  overflow: auto;
}
.topBar{
  padding-top: 0px;
  padding-left: 0px;
  padding-right:0px;
  height: 7vh;
}
.button{
  height:10%;
  width:40%;
  background-color: black;
  color:white;
  border: 3px red solid;
  border-radius: 20px;
  margin: 5%;
  font-size:medium;
}
.button:hover{
  height:15%;
  width:60%;
  font-size: x-large;
}
.body-div{
  /*border: 3px solid green;*/
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
#login-panel{
  flex: 1;
  text-align: center;
  align-items: center;
  /*border: 3px solid red;*/
  height: 100%;
  width: 50%;
}
.panels{
  flex: 1;
  float: left;
  /*border: 3px solid red;*/
  height: 100%;
  width: 50%; 
}
.formItems, .formAccessories{
  display: block;
  margin: 40px;
  height: 30px;
}
.formAccessories{
  /*border: 3px solid purple;*/
  text-align: left;
  margin-right: 10%;
  padding: 2.778px;
}
.formSelect{
  display: block;
  margin: 40px;
  height: 35.56px;
  padding: 4px;
  width: 70%;
}
.formItems{
  width: 67%;
}
.formBox{
  display: block;
  margin: 42.778px;
  height: 30px;
}
#login-div{
  text-align: center;
  align-items: center;
  display: block;
  width: 65%;
  margin-left: 36%;
}
#login-panel{
  text-align: center;
  align-items: center;
  /*border: 3px solid red;*/
  height: 100%;
  width: 50%;
}
.quickTest{
  height: 100%;
  /*border: 3px solid blue;*/
}

.load {
  font-size: 150%;
  padding: 1%;
  margin: 1%;
  text-align: center;
}

h2 {
  font-size: 220%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
#forErrorMessages{
  text-align: center;
  font-size: 130%;
  font-weight: 600;
  vertical-align: middle;
}
.orgEntry, .areSure {
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #3a6d06;
  background-color: #70c4dd2e;
  border-radius: 10px;
  padding: 10px;
  margin-left: 35%;
  width: 30%;
  margin-top: 15px;
  text-align: left;
}
.trash, .edit{
  margin-left: 15%;
  width: 27.5%;
  background-color: #0e5384;
  color: #FFFFFF;
  padding: 2%;
  border-radius: 7px;  
  font-family: 'Open Sans', sans-serif;
}
#modSearchBox{
  margin-left: 40%;
  width:20%;
}
#modSearchButton{
  background-color: #0e5384;
  color: #FFFFFF;
  border: 5px solid #0e5384;
  border-radius:7px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  padding: 1.5%;
  padding-left: 3%;
  padding-right: 3%;
}

.col1 {
  flex-shrink: 100%;
  flex-direction: column;
  float: left;
  flex: 20%;
  width: 18%;
  margin: 0.5%;
  padding-left: 5%;
}

.areSure {
    display: block;
    font-size: 1em;
    font-weight: bold;
}

.sure, .unsure {
  background-color: #0e5384;
  color: #FFFFFF;
  border: 5px solid #0e5384;
  border-radius:7px;
  display: inline-flexbox;
  margin: 1%;
  text-align: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  padding: 1.5%;
  padding-left: 3%;
  padding-right: 3%;
}
