@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');


.fancy-button{
    background-color: #449aad;
    color: #FFFFFF;
    border: 5px solid #449aad;
    border-radius:20px;
    vertical-align: middle;
    width: 15%;
    height: 20%;
    font-weight: 500;
    padding: 1%;
    cursor: pointer;
    margin: 2%;
    text-align: center;
    line-height: 150%;
    display: inline-block;
    align-items: baseline;
}
.fancy-button:hover{
    animation-name: mouseNonspecial;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes mouseNonspecial{
    from {background-color: #449aad;
        color: #FFFFFF;
    }
    to{background-color: #FFFFFF;
        color: #449aad;
    }
}
.special, #submission{
    background-color: #0e5384;
    color: #FFFFFF;
    border: 5px solid #0e5384;
    border-radius:7px;
    display: inline-flex;
    text-align: center;
    width: 200px;
    height: 86px;
    margin: 3%;
    padding: 1%;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
}
.special:hover, #submission:hover{
    animation-name: mouseSpecial;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes mouseSpecial{
    from {background-color: #0e5384;
        color: #FFFFFF;
    }
    to{background-color: #FFFFFF;
        color: #0e5384;
    }
}
#submission {
    max-width: 240px;
    max-height: 190px;
    min-width: 210px;
    min-height: 125px;
    width: 30%;
    height: auto;
    line-height: -10px;
}
.textP, #submission {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
}